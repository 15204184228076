<template>
  <div>
    <div class="jumbotron-wrapper color">
      <div class="jumbotron container-lg px-4">
        <div class="animation cover-text mt-5">
          <div class="row gx-5">
            <header class="mb-4">
              <p class="fs-1 fw-bolder mb-1">
                国投证券（原“安信证券”）携手非凸科技、泽募家，共话数智交易未来投资之道
              </p>
              <div class="fst-italic mb-2">2023年7月24日</div>
              <a
                class="badge bg-secondary text-decoration-none link-light"
                href="#!"
                >新闻</a
              >
              <a
                class="ms-1 badge bg-secondary text-decoration-none link-light"
                href="#!"
                >国投证券</a
              >
            </header>
          </div>
        </div>
      </div>
    </div>
    <div class="content p-auto">
      <section class="py-5">
        <div class="container-lg px-4">
          <div class="row">
            <div class="col-lg-3">
              <div class="d-flex align-items-center mt-lg-5 mb-4">
                <img
                  class="img-fluid rounded-circle logo_50"
                  src="../../assets/img/logo/logo_50.jpeg"
                  alt="非凸科技"
                />
                <div class="ms-3">
                  <div class="fw-bold">非凸新闻</div>
                  <div class="text-muted">Non-convex News</div>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <article class="lh-lg">
                <section class="mb-5">
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/49/01-主题.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    随着AI在数智投资领域的应用日益广泛，越来越多的金融机构将其视为核心竞争力，而持续获得高超额收益也成为制胜的关键。7月19日，国投证券联合非凸科技、泽募家在上海举办了私募基金分享论坛，与在场嘉宾共同探讨数智交易的未来投资之道。
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/49/02-安信.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    当前，国投证券以“创新驱动科技赋能”为目标，加强科技创新与业务融合共创，持续推进金融科技创新探索与实践，助力行业高质量发展。国投证券还积极搭建企业链接资本市场的桥梁，始终以客户为中心，充分运用股票、债券等多种金融工具，为投资者提供综合金融服务。会上，国投证券金融衍生品部华东区域销售负责人刘博洋分享了如何利用场外衍生品构建气囊指增及另类指增策略。他表示，“指数增强策略管理人通过使用看跌类期权进行对冲，以低廉的成本对冲指数下行风险的同时，能够保留指数上涨收益。相比市场上常见的对冲方式，看跌类期权具有成本相对较低、不对冲上行收益、无需管理等优势。”
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/49/03-泽募家.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    泽募家是一家以金融信息科技为核心的SAAS服务平台，致力于为管理人和财富机构提供全场景的一站式金融服务。泽募家权益合伙人梁伟华表示，“希望发挥泽募家团队优势，为私募管理人提供更全面更优质的服务，为其带来规模上看得见的增长，同时建设并打通资产端与资金端，形成完整的业务链条闭环，双向赋能，共同成长。”
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/49/04-非凸.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    在AI时代，金融行业的竞争将进一步集中在数据、算力和算法领域，而作为第三方服务商的非凸科技，也在通过AI前沿技术持续赋能行业发展。非凸科技机构业务经理方芳表示，“从交易场景的真实需求出发，非凸科技逐步实现了AI算法及交易工具生态建设，同时创新性地通过机器学习、深度学习、强化学习等人工智能技术研发，持续迭代和升级，并基于Rust语言构建了高安全性、稳定性、高并发、低延迟算法交易系统，累计收获了百家私募机构客户及头部券商的实践好评。”面对技术新潮，非凸科技也将与时俱进，不断提升策略迭代效率，帮助投资者稳定获取更多超额收益。
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/49/05-茶歇.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    感谢国投证券与泽募家的支持，期待未来有更多的联动和交流，打造合作共赢生态圈。
                  </p>
                </section>
              </article>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "News49",
};
</script>

<style></style>
